<script setup>
import { ref, computed } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { procSJAWorkflowAction } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';
import { alert, confirm } from 'o365-vue-services';

const props = defineProps({
    actionRow: Object,
    sjaID: Number,
    readOnly: Boolean
});

const procSendActionReminder = getOrCreateProcedure({ id: "procSendActionReminder", procedureName: "astp_SJA_SendTaskEmail" });
let dsResponsible = getDataObjectById(`dsTasksHazardsActionsResp_${props.sjaID}`);
let dsSJA = getDataObjectById(`dsSJA_${props.sjaID}`)
let isHidden = ref(false);

const responsibleData = computed(() => {
    return dsResponsible.data.filter(item => item.SJATasksHazardsAction_ID == props.actionRow.ID);
});

async function createWorkflowAction(responsible, actionRow, stepID) {
    isHidden.value = true;
    try {
        await procSJAWorkflowAction.execute({ Step_ID: stepID, Responsible_ID: responsible.Responsible_ID, ResponsibleRow_ID: responsible.ID, Action_Title: actionRow.Name, Action_Comment: actionRow.Comment });
    }
    finally {
        await dsResponsible.load();
        isHidden.value = false;
    }
}

function sendReminder(person) {
    procSendActionReminder.execute({ SJA_ID: person.SJA_ID, Person_ID: person.Responsible_ID, SJATasksHazardsAction_ID: person.SJATasksHazardsAction_ID, TypeCode: 'SJA_ACTION_REMINDER' })
        .then(() => {
            alert($t('Reminder e-mail has been sent'), 'success', { autohide: true, delay: 5000 });
        })
        .catch((e) => {
            alert($t('An error has occurred, ') + e);
        });
}

async function removeResponsible(row) {
    await confirm({
        title: $t("Remove person"),
        message: $t('Are you sure you wish to remove') + ' ' + row.Name + '?',
        btnTextOk: $t("Yes"),
        btnTextCancel: $t("No"),
    }).then(async () => {
        await row.delete();
    }).catch(() => {
    });
}

</script>

<template>
    <span v-for="(responsible, index) in responsibleData" class="border mt-1 d-flex flex-row flex-no-wrap p-1 me-2">
        <span class="me-auto d-inline-block text-truncate" :title="responsible.Name">
            {{ responsible.Name }}
        </span>
        <div v-if="!props.readOnly" class="d-flex align-items-center">
            <div class="dropdown ms-auto">
                <button class="btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="height: 17px;vertical-align: text-top;">
                    <i class="bi bi-chevron-down"></i>
                </button>
                <div class="dropdown-menu z-3" aria-labelledby="dropdownMenuButton" style="line-height: 1;">
                    <button class="dropdown-item" @click="sendReminder(responsible)" :title="$t('Send the responsible a reminder e-mail')">{{ $t("Send reminder e-mail")}}</button>
                    <div v-if="!props.readOnly"
                        :title="$t('Remove person.')" style="-bs-btn-line-height: 1;" class="dropdown-item" @click="responsible.delete(null, { o_DeleteConfirm: false })">
                        {{ $t('Remove person') }}
                    </div>
                    <button class="dropdown-item" v-if="!props.readOnly && dsSJA.current.Step_ID && !responsible.StepAction_ID" @click="createWorkflowAction(responsible, props.actionRow, dsSJA.current.Step_ID)">
                            {{ $t('Create workflow action') }}
                    </button>
                    <button v-if="responsible.StepAction_ID" class="dropdown-item" disabled>
                        <i class="me-1 bi bi-circle-fill"
                            :class="{ 'text-danger': !responsible.Completed, 'text-success': responsible.Completed }"></i>
                    </button>
                </div>
            </div>
        </div>
    </span>
</template>